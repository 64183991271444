<template>
  <div class="about">

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Payout : {{selectedPayout.id}}</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Payout Amount: ${{selectedPayout.payout}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Status: {{selectedPayout.status}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-form
          class='pa-5'
          ref="form"
          v-model="valid"
          lazy-validation
        >

          <v-text-field
            v-model="selectedPayout.id"
            disabled
            label="Invoice ID"
            required
          ></v-text-field>

          <v-text-field
            :disabled='disabled'
            v-model="selectedPayout.methods"
            :rules="[v => !!v || 'Item is required']"
            label="Payment Method ( ex : Paypal, check, stripe , Venmo)"
            required
          ></v-text-field>

          <v-text-field
            :disabled='disabled'
            v-model="selectedPayout.note"
            :rules="[v => !!v || 'Item is required']"
            label="Notes"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid || disabled"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-form>

      </v-card>
    </v-dialog>

    <h1 class='pa-3'>Manage Payout</h1>
    <v-data-table
      v-model='selected'
      show-select
      :headers="headers"
      :items="payments"
      :search="search"
    >

      <template v-slot:item.payout="{item }">
        ${{item.payout}}

      </template>

      <template v-slot:item.id="{item }">
        <a
          @click='dialog = true; checkEnable(item)'
          style='text-decoration : underline'
        >${{item.id}}</a>
      </template>

    </v-data-table>
  </div>
</template>


<script>
import axios from "axios";
const fb = require("../../firebaseconfig");
export default {
  methods: {
    checkEnable(item) {
      this.selectedPayout = item;
      if (this.selectedPayout.status !== "open") {
        this.disabled = true;
      }
    },
    submit() {
      let valid = this.$refs.form.validate();
      if (valid) {
        axios
          .post(
            "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/submitPayout",
            {
              uid: this.token,
              npoid: this.selectedPayout.npo,
              id: this.selectedPayout.id,
              notes: this.selectedPayout.note,
              method: this.selectedPayout.methods,
              npoName: this.selectedPayout.npoName,
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(() => {
            this.dialog = false;
          });
      } else {
        return;
      }
    },
  },
  data() {
    return {
      disabled: false,
      token: null,
      notes: "",
      valid: true,
      selectedPayout: {
        donations: [],
        id: null,
        lastUpdate: null,
        methods: null,
        payout: 0,
        refurbisher: null,
        status: null,
        note: null,
      },
      dialog: false,
      payments: [],
      selected: [],
      headers: [
        { text: "Status", value: "status" },
        { text: "Payout ID", value: "id" },
        { text: "Donations", value: "donations" },
        { text: "Last Update", value: "lastUpdate", filterable: true },
        { text: "NPO", value: "npoName" },
        { text: "Total Value", value: "payout" },
      ],
      search: null,
    };
  },

  async mounted() {
    if (!this.globalRefurbisher) {
      let idTokenResult = await fb.auth.currentUser.getIdTokenResult(true);
      this.token = idTokenResult.token;
      this.globalRefurbisher = idTokenResult.claims.id;
    }
    fb.db
      .collection("payment")
      .where("refurbisher", "==", this.globalRefurbisher)
      .onSnapshot((data) => {
        this.payments = data.docs.map((item) => item.data());
      });
  },
};
</script>