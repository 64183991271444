<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-toolbar-title>
        <v-img
          width='45%'
          src='https://storage.googleapis.com/donorconnection-df169.appspot.com/dc-logo-small.png'
        />
      </v-toolbar-title>

      <v-container class="py-0 fill-height">

        <div v-if="isAuthenticated">
          <v-btn
            v-for="link in links"
            :key="link.name"
            text
            :to='link.url'
          >
            {{ link.text }}
          </v-btn>

        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAuthenticated"
          @click='downloadURL("https://storage.googleapis.com/donorconnection-df169.appspot.com/WinPE_amd64.iso")'
          title='Download ISO file'
          icon
        >
          <v-icon>mdi-usb-flash-drive</v-icon>
        </v-btn>
        <v-btn
          v-if="isAuthenticated"
          @click='downloadURL("https://storage.googleapis.com/donorconnection-df169.appspot.com/TechSpec-DonorConnection.zip")'
          title='Download Techspec Software for Desktop'
          large
          icon
        >
          <v-icon>mdi-desktop-tower-monitor</v-icon>
        </v-btn>
        <v-btn
          v-if="isAuthenticated"
          text
          @click='signout'
        >Sign out</v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <router-view />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields";

const fb = require("../firebaseconfig");
export default {
  methods: {
    downloadURL(url) {
      var link = document.createElement("a");
      link.download = name;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    signout() {
      fb.auth.signOut();
    },
    search() {
      if (!this.$route.path.includes(this.searchKey)) {
        this.$router.push("/donations/" + this.searchKey);
        this.searchKey = "";
      }
    },
  },
  mounted() {
    fb.auth.onAuthStateChanged((user) => {
      if (user === null) {
        this.isAuthenticated = false;
      } else {
        fb.auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
          this.globalRefurbisher = idTokenResult.claims.id;
          this.isAuthenticated = true;
          fb.db
            .collection("refurb")
            .where("id", "==", idTokenResult.claims.id)
            .onSnapshot((docs) => {
              docs.forEach((doc) => {
                this.ngoData = doc.data().ngoData;
              });
            });
        });
      }
    });
  },
  computed: {
    ...mapFields(["isAuthenticated", "user", "globalRefurbisher", "ngoData"]),
  },
  data: () => ({
    searchKey: null,
    links: [
      { text: "Home", url: "/home" },
      { text: "Donations", url: "/donations" },
      { text: "Manage Payout", url: "/payment" },
      { text: "Account", url: "/account" },
    ],
  }),
};
</script>