import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import npoDonations from "../views/npoDonations.vue"
import donations from '../views/donations.vue'
import payment from '../views/payment.vue'
import account from '../views/account.vue'
import login from '../views/login.vue'
import PageNotFound from "../views/404.vue"
import donationsDetail from "../views/donationDetail.vue"
import asset from "../views/asset"
import * as fb from '../../firebaseconfig'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/npoDonations/:npo/:name',
    name: 'npoDonations',
    component: npoDonations,
    meta: { requiresAuth: true }
  },
  {
    path: '/donations',
    name: 'Donations',
    component: donations,
    meta: { requiresAuth: true }
  },
  {
    path: '/donations/:id',
    name: 'donationsDetail',
    component: donationsDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/asset/:id',
    name: 'Asset',
    component: asset,
    meta: { requiresAuth: true }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: payment,
    meta: { requiresAuth: true }
  },
  { path: "*", component: PageNotFound },
  {
    path: '/account',
    name: 'Account',
    component: account,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    fb.auth.onAuthStateChanged(user => {
      if (!user) {
        next({
          path: "/"
        })
      } else {
        next()
      }
    })
    // this route requires auth, check if logged in
    // if not, redirect to login page.
  } else {
    next() // make sure to always call next()!
  }
})
export default router
