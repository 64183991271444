<template>
  <div>
    <v-card>
      <v-card-title>
        All Donations
        <v-spacer></v-spacer>
        <v-btn
          class='info'
          @click='dialog = true'
          :disabled='selected.length === 0'
        >Confirm Received {{selected.length}} donation</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model='selected'
        show-select
        :headers="headers"
        item-key="id"
        :items="assets"
        :search="search"
        @toggle-select-all="selectAllToggle"
      >
        <template v-slot:item.receivedDate="{item}">
          {{item.receivedDate}} {{dayElapsed(item.receivedDate)}}
        </template>

        <template v-slot:item.data-table-select="{ item,isSelected,select}">
          <v-simple-checkbox
            :ripple='false'
            :value="isSelected"
            :disabled="item.status === 'received'"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>

        <template v-slot:item.ngo="{item }">
          {{getNGOName(item.ngo)}}

        </template>
        <template v-slot:item.tracking="{item }">
          <a
            :href='`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${item.tracking}&requester=ST/trackdetails`'
            target='blank'
          >{{item.tracking}}</a>
        </template>

        <template v-slot:item.paymentID="{item }">
          {{item.paymentID ? "Yes" : "No"}}

        </template>

        <template v-slot:item.Value="{item }">
          ${{item.Value}}

        </template>

        <template v-slot:item.id="{item }">
          <a
            style='text-decoration: underline;'
            @click='goTo(item)'
          >{{item.id}}</a>

        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="headline">
          Confirm receiving donations
        </v-card-title>
        <v-card-text>this process can't be undone, do you want to contiune confirm receiving the donation?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmReceived"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
import { mapFields } from "vuex-map-fields";
const fb = require("../../firebaseconfig");
export default {
  computed: {
    ...mapFields(["ngoData"]),
  },
  data() {
    return {
      disabledCount: 0,
      claims: null,
      dialog: false,
      selected: [],
      headers: [
        { text: "Status", value: "status" },
        { text: "Donation ID", value: "id" },
        { text: "Date Received", value: "receivedDate", filterable: true },
        { text: "NPO", value: "ngo" },
        { text: "Finalized Value", value: "paymentID" },
        { text: "Total Value", value: "Value" },
        { text: "# of Assets", value: "computerToDonate" },
        { text: "Description", value: "description" },
        { text: "Tracking", value: "tracking" },
      ],
      search: null,
      donations: null,
      assets: [],
    };
  },
  async mounted() {
    fb.auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
      this.claims = idTokenResult.claims;
      console.log(idTokenResult.claims.id);
      await fb.db
        .collection("donations")
        .where("refurbisherId", "==", idTokenResult.claims.id)
        .onSnapshot((doc) => {
          this.assets = doc.docs.map((data) => data.data());
          this.assets.map((item) => {
            if (item.status === "received") this.disabledCount += 1;
          });
        });
    });
  },
  methods: {
    selectAllToggle(props) {
      if (this.selected.length !== props.items.length - this.disabledCount) {
        this.selected = [];
        props.items.forEach((item) => {
          if (item.status !== "received") {
            this.selected.push(item);
          }
        });
      } else this.selected = [];
    },
    goTo(item) {
      this.$router.push("/donations/" + item.id);
    },
    dayElapsed(time) {
      if (time) {
        return "(" + moment().diff(time, "days") + "days old)";
      } else {
        return "Not yet received";
      }
    },
    async confirmReceived() {
      let token = await fb.auth.currentUser.getIdToken();
      this.selected.forEach(async (item) => {
        if (item.refurbisherReceived) {
          alert("This donation is in bad state");
          this.dialog = false;
          return;
        }
        const doc = await fb.db.collection("donations").doc(item.id).get();

        fb.db
          .collection("donations")
          .doc(doc.id)
          .update({
            status: "received",
            receivedDate: moment()
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY hh:mm:ss"),
            refurbisherReceived: true,
            step: 2,
          })
          .then(() => {
            axios
              .post(
                "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/sendReceivedNotifyEmail",
                {
                  uid: token,
                  donationID: doc.data().id,
                },
                { headers: { "Content-Type": "application/json" } }
              )
              .then(function (response) {
                console.log(response);
              });
          });

        this.dialog = false;
      });
    },
    getNGOName(id) {
      let result = this.ngoData.find((item) => item.id === id);
      if (!result) {
        return "error";
      }
      return result.name;
    },
  },
};
</script>