<template>
  <div>
    <v-card>
      <v-card-title>
        Donations for {{this.$route.params.name}}
        <v-spacer></v-spacer>
        <v-btn
          class='info'
          @click='dialog = true'
          :disabled='selected.length === 0'
        >Confirm Received {{selected.length}} donation</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model='selected'
        show-select
        :headers="headers"
        :items="assets"
        :search="search"
      >
        <template v-slot:item.receivedDate="{ item }">
          {{item.receivedDate}} {{dayElapsed(item.receivedDate)}}
        </template>

        <template v-slot:item.ngo>
          {{$route.params.name}}

        </template>
        <template v-slot:item.tracking="{item }">
          <a
            :href='"https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1="+item.tracking'
            target='blank'
          >{{item.tracking}}</a>
        </template>

        <template v-slot:item.id="{item }">
          <a
            style='text-decoration: underline;'
            @click='goTo(item)'
          >{{item.id}}</a>

        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="headline">
          Confirm receiving donations
        </v-card-title>
        <v-card-text>this process can't be undone, do you want to contiune confirm receiving the donation?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmReceived"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
const fb = require("../../firebaseconfig");
export default {
  data() {
    return {
      claims: null,
      dialog: false,
      selected: [],
      headers: [
        { text: "Status", value: "status" },
        { text: "Donation ID", value: "id" },
        { text: "Date Received", value: "receivedDate", filterable: true },
        { text: "NPO", value: "ngo" },
        { text: "Total Value", value: "Value" },
        { text: "# of Assets", value: "computerToDonate" },
        { text: "Description", value: "description" },
        { text: "Tracking", value: "tracking" },
      ],
      search: null,
      donations: null,
      ngo: [],
      assets: [],
    };
  },
  async mounted() {
    fb.auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
      this.claims = idTokenResult.claims;
      await this.getDonations(this.$route.params.npo, this.$route.query.status);
    });
  },
  methods: {
    goTo(item) {
      this.$router.push("/donations/" + item.id);
    },
    dayElapsed(time) {
      if (time) {
        return "(" + moment().diff(time, "days") + "days old)";
      } else {
        return "Not yet received";
      }
    },
    async confirmReceived() {
      let token = await fb.auth.currentUser.getIdToken();
      this.selected.forEach(async (item) => {
        if (item.refurbisherReceived) {
          console.log("bad state");
          this.dialog = false;
          return;
        }
        const doc = await fb.db.collection("donations").doc(item.id).get();
        fb.db
          .collection("donations")
          .doc(doc.id)
          .update({
            status: "received",
            receivedDate: moment()
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY hh:mm:ss"),
            refurbisherReceived: true,
            step: 2,
          })
          .then(() => {
            axios
              .post(
                "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/sendReceivedNotifyEmail",
                {
                  uid: token,
                  donationID: doc.data().id,
                },
                { headers: { "Content-Type": "application/json" } }
              )
              .then(function (response) {
                console.log(response);
              });
          });
        this.dialog = false;
      });
    },
    getNGOName(id) {
      let result = this.ngo.find((item) => item.id === id);
      return result.name;
    },

    async getDonations(id, status) {
      if (status === "all") {
        await fb.db
          .collection("donations")
          .where("ngo", "==", id)
          .where("refurbisherId", "==", this.claims.id)
          .onSnapshot((query) => {
            this.assets = [];
            query.forEach((ngo) => {
              this.assets.push(ngo.data());
            });
          });
      } else {
        await fb.db
          .collection("donations")
          .where("ngo", "==", id)
          .where("status", "==", status)
          .where("refurbisherId", "==", this.claims.id)
          .onSnapshot((query) => {
            this.assets = [];
            query.forEach((ngo) => {
              this.assets.push(ngo.data());
            });
          });
      }
    },
  },
};
</script>