<template>
  <v-container>
    <v-dialog
      v-model="printDialog"
      max-width="320"
    >
      <v-card>
        <v-card-title class="headline">
          Print Barcode Labels
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for='(item,index) in assets'
            :key='index'
            v-model="assetsID"
            :label="item.assetTag.toString()"
            :value="item.assetTag"
          ></v-checkbox>
          <v-select
            v-model="selectedPrinter"
            :items="printerList"
            label="Select Printer"
            :rules="printerRules"
            required
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="printDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="printLabel"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      v-if='!donation.refurbisherReceived'
      dense
      border="left"
      type="warning"
    >
      The refurbisher has not yet received this donation yet.
    </v-alert>

    <v-alert
      v-if='donation.paymentID !== null'
      dense
      type="info"
    >
      The refurbisher has finalized the value for this donation
    </v-alert>
    <v-row>

      <v-col cols='1'></v-col>

      <v-col cols='11'>

        <v-btn
          primary
          :disabled='donation.status === "completed"'
          @click='completeAudit'
        >{{donation.status === "completed" ? "Audit already completed": "Complete audit"}}</v-btn>

        <v-btn
          :disabled='donation ? donation.Value === "0" || donation.paymentID !== null : false'
          @click='finalizeValue'
          class='ml-5'
        >

          <v-icon>mdi-check</v-icon>Confirm Donation Lot Value
        </v-btn>

        <v-btn
          @click='printDialog = true'
          class='ml-5'
        >

          <v-icon>mdi-printer</v-icon>Print Labels
        </v-btn>

      </v-col>

    </v-row>

    <v-row>

      <v-col cols='1'>

      </v-col>

      <v-col
        cols='5'
        v-if='donation'
      >

        <v-card
          class="mx-auto ma-5"
          elevation="2"
        >

          <v-card-title>

            <v-icon large>

              mdi-package-variant-closed

            </v-icon>

            Donation : {{this.$route.params.id}}
            <br />

          </v-card-title>

          <v-card-text>

            <div class="my-4 subtitle-1">

              Lot Number : <strong><a @click='routeToLot(donation.lotNumber)'>{{donation.lotNumber}}</a></strong>
              <v-icon
                style="margin-left : 4px"
                title='view lot in TechSpec'
                @click='routeToLot(donation.lotNumber)'
              >mdi-open-in-new</v-icon>

            </div>

            <div class="my-4 subtitle-1">

              Donation created at : <strong>{{donation.time}} ({{dayElapsed(donation.time)}} days old)</strong>

            </div>

            <div class="my-4 subtitle-1">

              Are all devices functional and less than 7 years old? : <strong>{{donation.isFunctionalAnd7YearsOld}}</strong>

            </div>

            <div class="my-4 subtitle-1">

              Items to donate : <strong>{{donation.computerToDonate}}</strong>

            </div>

            <div class="my-4 subtitle-1">

              Description : <strong>{{donation.description}}</strong>

            </div>

          </v-card-text>

        </v-card>

        <v-card
          class="mx-auto ma-5"
          elevation="2"
        >

          <v-card-title>
            Tracking # <a
              class='ml-3'
              :href='`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${donation.tracking}&requester=ST/trackdetails`'
              target='blank'
            >{{donation.tracking}}</a>

          </v-card-title>

        </v-card>

        <v-card
          class="mx-auto ma-5"
          elevation="2"
        >

          <v-card-title>

            Total value of this donation

            <v-icon
              title='update value'
              @click='updateValue'
            >mdi-refresh</v-icon>

          </v-card-title>

          <v-card-text>

            <div class="my-4 subtitle-1">

              <h2> ${{donation.Value}}</h2>

            </div>

          </v-card-text>

        </v-card>

      </v-col>

      <v-col cols='6'>

        <v-card
          v-for='(item,index) in assets'
          :key='index'
          class="mx-auto ma-5"
          max-width="430"
          outlined
        >

          <v-list-item
            three-line
            @click='routeTo(item.assetTag)'
          >

            <v-list-item-content>

              <v-list-item-title class="headline mb-1">

                {{item.assetTag}} <a style='color:green'>(${{item.Value}})</a>

              </v-list-item-title>

            </v-list-item-content>

            <v-list-item-avatar size="80">

              <v-icon
                :color='itemColor(item)'
                x-large
              >

                {{Asseticon(item)}}

              </v-icon>

            </v-list-item-avatar>

          </v-list-item>

        </v-card>

      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import moment from "moment";
import axios from "axios";
const fb = require("../../firebaseconfig");
import { mapFields } from "vuex-map-fields";
export default {
  data() {
    return {
      dymo: null,
      selectedPrinter: null,
      printerRules: [(v) => !!v || "Printer is required"],
      printerList: [],
      assetsID: [],
      printDialog: false,
      labels: [],
      ItemColor: "green",
      donation: {
        Value: 0,
        assets: [],
        computerAge7orless: null,
        computerToDonate: 0,
        description: null,
        donor: null,
        id: null,
        isFunctional: null,
        ngo: null,
        ngoReceived: false,
        paymentID: null,
      },
      assets: [],
      barcodeLabelURL:
        "https://storage.googleapis.com/donorconnection-df169.appspot.com/barcode.label",
    };
  },
  computed: {
    ...mapFields(["globalRefurbisher"]),
  },
  methods: {
    prepareBarcodeLabel(element) {
      let label = this.dymo.openLabelFile(this.barcodeLabelURL);
      label.setObjectText("BARCODE", element);
      label.setObjectText("lotNum", this.donation.id);
      return label;
    },
    printLabel() {
      if (!this.selectedPrinter) {
        return alert("Please Select a printer");
      }
      this.labels = [];
      this.assetsID.forEach((asset) => {
        let label = this.prepareBarcodeLabel(asset);
        this.labels.push(label);
      });

      this.labels.forEach((plabel) => {
        plabel.print(this.selectedPrinter);
      });
      this.printDialog = false;
    },
    async finalizeValue() {
      await this.updateValue();
      let token = await fb.auth.currentUser.getIdToken();
      axios.post(
        "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/confirmDonationValue",
        {
          lotValue: this.totalValue(),
          uid: token,
          npo: this.donation.ngo,
          donationID: this.donation.id,
        },
        { headers: { "Content-Type": "application/json" } }
      );
    },
    totalValue() {
      let totalVal = 0;
      this.assets.forEach((item) =>
        Math.round((totalVal += parseFloat(item.Value)))
      );
      return totalVal.toFixed(2);
    },
    async updateValue() {
      await fb.db.collection("donations").doc(this.donation.id).update({
        Value: this.totalValue(),
      });
    },
    async completeAudit() {
      if (this.donation.status === "completed") {
        return alert("This Donation is already in completed state.");
      } else if (!this.donation.refurbisherReceived) {
        return alert(
          "Please confirm you have received this donation before completing"
        );
      }

      let token = await fb.auth.currentUser.getIdToken();
      await fb.db
        .collection("donations")
        .doc(this.donation.id)
        .update({
          status: "completed",
          step: 3,
        })
        .then(() => {
          axios.post(
            "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/completeDonation",
            {
              uid: token,
              donationID: this.donation.id,
            },
            { headers: { "Content-Type": "application/json" } }
          );
        });
    },
    itemColor(item) {
      if (item.status === "Not Functional" || item.status === "Discovered") {
        return "green";
      } else if (
        item.status === "not received" ||
        item.status === "Not Received"
      ) {
        return "red";
      } else {
        return "grey";
      }
    },
    Asseticon(item) {
      if (item.status === "Not Functional") {
        this.ItemColor = "green";
        return "mdi-recycle";
      } else if (
        item.status === "not received" ||
        item.status === "Not Received"
      ) {
        this.ItemColor = "red";
        return "mdi-map-marker-question-outline";
      } else if (item.status === "Discovered") {
        this.ItemColor = "green";
        return "mdi-check-circle";
      } else {
        return "mdi-account-clock";
      }
    },
    dayElapsed(time) {
      return moment().diff(time, "days");
    },
    routeTo(asset) {
      window.open(
        "https://portal.donorconnection.org/public/" + asset,
        "_blank"
      );
    },
    routeToLot(lotnumber) {
      window.open(
        "https://portal.donorconnection.org/app/lots/" + lotnumber,
        "_blank"
      );
    },
  },
  async mounted() {
    this.$loadScript(
      "https://storage.googleapis.com/tech-reuse.appspot.com/DYMO.Label.Framework.3.0.js"
    ).then(() => {
      // eslint-disable-next-line
      this.dymo = dymo.label.framework;
      var printers = this.dymo.getPrinters();
      for (var i = 0; i < printers.length; i++) {
        var printer = printers[i];
        if (printer.printerType == "LabelWriterPrinter") {
          this.printerList.push(printer.name);
        }
      }
    });
    if (!this.globalRefurbisher) {
      let idTokenResult = await fb.auth.currentUser.getIdTokenResult(true);
      this.globalRefurbisher = idTokenResult.claims.id;
    }
    fb.db
      .collection("donations")
      .doc(this.$route.params.id)
      .onSnapshot((data) => {
        this.donation = data.data();
        console.log(this.donation);
      });

    fb.db
      .collection("assets")
      .where("donations", "==", this.$route.params.id)
      .where("refurbisher", "==", this.globalRefurbisher)
      .orderBy("assetTag")
      .get()
      .then((data) => {
        this.assets = [];
        this.assetsID = [];
        data.forEach((doc) => {
          this.assets.push(doc.data());
          this.assetsID.push(doc.data().assetTag);
        });
      });
  },
};
</script>