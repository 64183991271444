<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          NPO : {{selectedNPO.name}}
        </v-card-title>
        <v-card-text>
          <h3 class='ma-5'>ID : {{selectedNPO.id}}</h3>
          <h3 class='ma-5'>Name : {{selectedNPO.name}}</h3>
          <h3 class='ma-5'>Email : {{selectedNPO.email}}</h3>
          <h3 class='ma-5'>Address : {{selectedNPO.address}}</h3>
          <h3 class='ma-5'>Phone Number : {{selectedNPO.phoneNumber}}</h3>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-card class="mx-auto">
      <v-toolbar
        color="teal"
        dark
      >
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Account Settings</v-toolbar-title>
      </v-toolbar>

      <v-list
        two-line
        subheader
      >
        <v-subheader>General:</v-subheader>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Refurbisher : {{companyName}}</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list
        subheader
        two-line
        flat
      >
        <v-subheader>Partner Non-profit Organization:</v-subheader>

        <v-list-item-group
          v-model="settings"
          multiple
        >
          <v-list-item
            @click='showNPO(item)'
            v-for='(item,index) in ngo'
            :key='index'
          >

            <v-list-item-avatar>
              <v-icon
                class="grey lighten-1"
                dark
              >
                mdi-domain
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title>Name : {{item.name}}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
const axios = require("axios");
const fb = require("./../../firebaseconfig");
export default {
  data: () => ({
    dialog: false,
    companyName: null,
    ngo: [],
    npoDetail: [],
    selectedNPO: {
      address: null,
      email: null,
      id: null,
      name: null,
      phoneNumber: null,
      refurb: null,
    },
    settings: [],
  }),
  mounted() {
    this.getNPOdetail();
    fb.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
      fb.db
        .collection("refurb")
        .where("id", "==", idTokenResult.claims.id)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            this.companyName = doc.data().name;
            this.ngo = doc.data().ngoData;
          });
        });
    });
  },
  methods: {
    showNPO(item) {
      this.selectedNPO = this.npoDetail.find((n) => n.id === item.id);
      this.dialog = true;
    },
    async getNPOdetail() {
      let idTokenResult = await fb.auth.currentUser.getIdTokenResult(true);
      let token = idTokenResult.token;
      axios
        .post(
          "https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/getNPOdetail",
          {
            uid: token,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.npoDetail = response.data;
        });
    },
  },
};
</script>