<template>
<div>
<v-system-bar
  height="45"
>
  <span class='display-1'>Asset Detail : {{this.$route.params.id}}</span>
   <v-spacer></v-spacer>
    <v-btn @click='updateAsset' small class='mr-3' >Update</v-btn>
    
    <v-btn @click='printPreview(false)' small class='mr-3'>Print Spec Label</v-btn>
      <v-btn @click='printPreview(true)' small >Print Asset Label</v-btn>
     
     
</v-system-bar>
   <v-container>

        <v-alert
         v-if='asset && !asset.isDiscovered'
      dense
      outlined
      type="error"
    >
    This asset has not been discovered yet, you can manually enter the hardware spec, but all data will be overwritten by techspec discovery tool.
    </v-alert>
    <v-alert
    v-if='paymentID !== null'
      dense
      type="info"
    >
      The refurbisher has finalized the value for this asset, no further changes can be made to the value.
    </v-alert>

        <v-row>
          <v-col>
               <v-select
              outlined
              dense
        :disabled='disabled'
        :items='assetType'
        class='pl-3 pt-3 pr-3'
        v-model='asset.assetType'
        label="Asset Type"
      ></v-select>

          </v-col>
           <v-col>
                <v-select
              outlined
              dense
        :disabled='disabled'
        :items='status'
        class='pl-3 pt-3 pr-3'
        v-model='asset.status'
        label="Status"
      ></v-select>

           </v-col>
            <v-col>
              <v-text-field
        :disabled='paymentID !== null'
        class='pr-3'
        v-model='asset.Value'
        label="Value"
      ></v-text-field>
            </v-col>
        </v-row>

        
       
       
           


     
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.notes'
        label="Notes"
      ></v-text-field>
       
       
       <v-snackbar
      v-model="snackbar"
    >
      {{ snackBarMessage }}
    </v-snackbar>
    <div v-if='asset.assetType === "computer"'>
    <v-row>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.SystemInfo.Make'
        label="Make"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.SystemInfo.Model'
        label="Model"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.SystemInfo.Serial'
        label="Serial"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.CpuTitle'
        label="Processor"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.RamTitle'
        label="Memory"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.HddTitle'
        label="Storage"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.Discovery.VideocardTitle'
        label="Video Card"
      ></v-text-field>
        </v-col>
        <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.sku'
        label="SKU"
      ></v-text-field>
        </v-col>
        <v-col md='4' sm='12' cols='12'>
      <v-select
        :disabled='disabled'
        :items='computerType'
        class='pl-3 pr-3'
        v-model='asset.mischardwarespec.computerType'
        label="Type"
      ></v-select>
        </v-col>
        <v-col md='4' sm='12' cols='12'>
      <v-select
        :disabled='disabled'
        :items='storageType'
        class='pl-3 pr-3'
        v-model='asset.mischardwarespec.storageType'
        label="Storage Type"
      ></v-select>
        </v-col>
        <v-col md='4' sm='12' cols='12'>
      <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.mischardwarespec.screensize'
        label="Screen Size"
        type='number'
      ></v-text-field>
        </v-col>
        <v-col md='4' sm='12' cols='12'>
      <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.mischardwarespec.color'
        label="Color"
      ></v-text-field>
        </v-col>
    </v-row>
         <v-subheader><b>Misc hardware spec</b></v-subheader>
        <v-container fluid>
       <v-layout
          row
          wrap
        >
      <v-flex
            v-for='(item,index) in checkBoxesList'
            sm4
            md4
            :key='index'
          >
            <v-checkbox
              :disabled='disabled'
              :label='item.name'
              v-model='asset.mischardwarespec[item.value]'
              hide-details
            ></v-checkbox>

          </v-flex>
       </v-layout>
        </v-container>
    </div>
    <div v-else>
      <v-row>
         <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Make'
        label="Make"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Model'
        label="Model"
      ></v-text-field>
        </v-col>
          <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Serial'
        label="Serial"
      ></v-text-field>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Storage'
        label="Storage"
      ></v-text-field>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Color'
        label="Color"
      ></v-text-field>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Carrier'
        label="Carrier"
      ></v-text-field>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
              <v-text-field
        :disabled='disabled'
        class='pl-3 pr-3'
        v-model='asset.PhoneTabletDiscovery.Imei'
        label="Imei"
      ></v-text-field>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
                  <v-select
              outlined
              dense
        :disabled='disabled'
        :items='yesNo'
       v-model='asset.PhoneTabletDiscovery.ActivationLock'
        label="Activation Lock"
      ></v-select>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
                <v-select
              outlined
              dense
        :disabled='disabled'
        :items='yesNo'
       v-model='asset.PhoneTabletDiscovery.CarrierLock'
        label="Carrier Lock"
      ></v-select>
          </v-col>
           <v-col md='4' sm='12' cols='12'>
              <v-select
              outlined
              dense
        :disabled='disabled'
        :items='yesNo'
       v-model='asset.PhoneTabletDiscovery.RemoteDeviceManagementLock'
        label="Remote Device Management Lock"
      ></v-select>
            
          </v-col>
      </v-row>
    </div>
          <v-dialog
    v-model="PrintdialogData"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Print Preview
      </v-card-title>
      <template v-for="(item,index) in previewArr">
        <img
          style='margin : 2em'
          v-bind:key='index'
          :src="item"
        />
      </template>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-select
          v-model="selectedPrinter"
          :items="printerList"
          label="Select Printer"
          :rules="printerRules"
          required
        ></v-select>
        <v-btn
          :disabled='labels.length === 0'
          color="primary"
          text
          @click="print()"
        >
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

   </v-container>
</div>
</template>


<script>

const fb = require("../../firebaseconfig");
import { mapFields } from "vuex-map-fields";
export default {
    data(){
        return{
          paymentID : null,
            barcodeLabelURL : "https://storage.googleapis.com/donorconnection-df169.appspot.com/barcode.label",
            computerLabelURL : "https://storage.googleapis.com/donorconnection-df169.appspot.com/template.label",
            phoneTableLabelURL: "https://storage.googleapis.com/donorconnection-df169.appspot.com/phoneTablet.label",
            PrintdialogData : false,
      selectedPrinter: "",
      labels: [],
      printerList: [],
      printerRules: [v => !!v || 'Printer is required'],
            dymo: null,
      previewArr: [],
            snackBarMessage : "",
            snackbar : false,
        docID : null,
        yesNo : ["yes","no"],
        assetType: ["computer","smart phone","tablet"],
        status : ["reused","not functional","not received"],
        computerType: [
        "Desktop",
        "Notebook / Laptop",
        "Ultrabook",
        "Subnotebook / Ultraportable",
        "Convertible 2 -in -1 Laptop / Tablet",
        "Netbook",
        "Ultra - mobile PC",
        "Chromebook",
        "Gaming Laptop",
        "Thinkpad"],
        storageType: [
        "eMMC",
        "HDD (Hard Disk Drive)",
        "HDD + SSD",
        "SSD (Solid State Drive)",
        "SSHD (Solid State Hybrid Drive)"
      ],

            disabled : false,
           
            asset : {
               PhoneTabletDiscovery: {
                  Make: null,
                  Model: null,
                  Serial: null,
                  Storage: null,
                  Color : null,
                  Carrier : null,
                  Imei : null,
                  ActivationLock : null,
                  CarrierLock : null,
                  RemoteDeviceManagementLock: null,
            },
              assetType : undefined,
              isDiscovered : true,
              notes : "",
              status : null,
              Discovery: {
                CpuTitle: null,
                HddTitle: null,
                RamTitle: null,
                VideocardTitle: null,
                SystemInfo: {
                    Make: null,
                    Model: null,
                    Serial: null,
                },
                
            },
              Value : 0,
              sku : null,
              mischardwarespec : {
                conditionDesc:null,
                computerType: null,
                screensize: null,
                color: null,
                storageType: null,
                displayPort: false,
                ethernet: false,
                minidisplayport: false,
                hdmi: false,
                microHDMI: false,
                microUSB: false,
                miniEthernet: false,
                miniUSB: false,
                usb1: false,
                usb2: false,
                lanCard: false,
                camera3d: false,
                backLitKeyboard: false,
                bluetooth: false,
                microphone: false,
                webcam: false,
                hsdpa: false,
                rugged: false,
                tabletPC: false,
                touchScreen: false,
            },

            },
                  checkBoxesList: [
        { name: "DisplayPort", value: "displayPort" },
        { name: "Gigabit Ethernet", value: "ethernet" },
        { name: "Mini DisplayPort", value: "minidisplayport" },
        { name: "HDMI", value: "hdmi" },
        { name: "Micro-HDMI", value: "microHDMI" },
        { name: "Micro-USB", value: "microUSB" },
        { name: "Mini Gigabit Ethernet", value: "miniEthernet" },
        { name: "Mini-USB", value: "miniUSB" },
        { name: "USB 1.0/1.1", value: "usb1" },
        { name: "USB 2.0", value: "usb2" },
        { name: "10/100 LAN Card", value: "lanCard" },
        { name: "3D Camera", value: "camera3d" },
        { name: "Backlit Keyboard", value: "backLitKeyboard" },
        { name: "Bluetooth", value: "bluetooth" },
        { name: "Built-in Microphone", value: "microphone" },
        { name: "Built-in Webcam", value: "webcam" },
        { name: "HSDPA", value: "hsdpa" },
        { name: "Rugged", value: "rugged" },
        { name: "Tablet PC", value: "tabletPC" },
        { name: "Touchscreen", value: "touchScreen" }
      ],
        }
    },
     watch: {
    globalRefurbisher: {
      handler: function(val) {
       if(val){
        this.getAssetsDetail()
       }
      },
    }
  }, 
      methods : {
          prepareBarcodeLabel(element){
          let label = this.dymo.openLabelFile(this.barcodeLabelURL);
          label.setObjectText("BARCODE", element.assetTag);
          label.setObjectText("lotNum", element.donations);
          return label;
          },
          
          prepareLable(element){

            if(element.assetType === "computer"){
                  if(!element.Discovery.SystemInfo.Serial){
        return alert("serial data is missing")
      }else if(!element.Discovery.CpuTitle){
        return alert("cpu data is missing")
      }
      else if(!element.Discovery.RamTitle){
        return alert("ram data is missing")
      }
      else if(!element.Discovery.HddTitle){
        return alert("hdd data is missing")
      }
      else if(!element.Discovery.SystemInfo.Make || !element.Discovery.SystemInfo.Model){
        return alert("make or model data is missing")
      }
            }else{
               if(!element.PhoneTabletDiscovery.Serial){
        return alert("serial data is missing")
      }else if(!element.PhoneTabletDiscovery.Make){
        return alert("manufacturer data is missing")
      }
      else if(!element.PhoneTabletDiscovery.Model){
        return alert("model data is missing")
      }
      else if(!element.PhoneTabletDiscovery.Carrier){
        return alert("carrier data is missing")
      }
      else if(!element.PhoneTabletDiscovery.Storage){
        return alert("storage data is missing")
            }
            }
          let label ;
      switch(element.assetType){
        case "computer":  
        label = this.dymo.openLabelFile(this.computerLabelURL)
        label.setObjectText("serialText", element.Discovery.SystemInfo.Serial)
        label.setObjectText("titleText", element.Discovery.SystemInfo.Make + " " + element.Discovery.SystemInfo.Model)
        label.setObjectText("cpuData", element.Discovery.CpuTitle)
        label.setObjectText("ramText", element.Discovery.RamTitle + " GB")
        label.setObjectText("hddText", element.Discovery.HddTitle + " GB")
        break;
        default : 
        label = this.dymo.openLabelFile(this.phoneTableLabelURL)
        label.setObjectText("serialText", element.PhoneTabletDiscovery.Serial)
        label.setObjectText("titleText", element.PhoneTabletDiscovery.Make + "," + element.PhoneTabletDiscovery.Model + "," + element.PhoneTabletDiscovery.Storage + ","+ element.PhoneTabletDiscovery.Carrier)
        label.setObjectText("modelText", element.PhoneTabletDiscovery.Model)
        label.setObjectText("carrierText", element.PhoneTabletDiscovery.Carrier)
        label.setObjectText("makeData", element.PhoneTabletDiscovery.Make)
        label.setObjectText("storageText", element.PhoneTabletDiscovery.Storage)
        break;
      }

        label.setObjectText("BARCODE", element.assetTag)
        label.setObjectText("qrCode", "URL:https://donorconnection-df169.web.app/public/asset/" + element.assetTag)

    return label;
    },
    print () {
      this.labels.forEach(plabel => {
        plabel.print(this.selectedPrinter)
      })
      this.PrintdialogData = false
    },
    printPreview (isBarcode) {
      this.labels = [];
      this.previewArr = [];
       if(this.asset.assetType === "computer"){
          delete this.asset.PhoneTabletDiscovery;

       }else{
         delete this.asset.mischardwarespec;
              delete this.asset.Discovery;
       }
        let label = isBarcode ? this.prepareBarcodeLabel(this.asset) : this.prepareLable(this.asset);
        this.labels.push(label)
        var pngData = label.render()
        var imgURI = "data:image/png;base64," + pngData

        this.previewArr.push(imgURI)






      this.PrintdialogData = true
    },
        updateAsset(){
          if(this.asset.assetType === "computer"){
            delete this.asset.PhoneTabletDiscovery;
            this.asset.isDiscovered = true;
            fb.db.collection("assets").doc(this.docID).update(this.asset).then(() => {
                this.snackBarMessage = "Asset Updated."
                this.snackbar = true;
            })
          }else{
              delete this.asset.mischardwarespec;
              delete this.asset.Discovery;
              this.asset.isDiscovered = true;
             fb.db.collection("assets").doc(this.docID).update(this.asset).then(() => {
                this.snackBarMessage = "Asset Updated."
                this.snackbar = true;
            })
          }
            
        },
        async getAssetsDetail(){
        let tempAssets = await fb.db.collection("assets").where("refurbisher","==",this.globalRefurbisher).where("assetTag","==",parseInt(this.$route.params.id)).get();
        
        this.docID = tempAssets.docs.map(item => item.id)[0];
        var a  = {
          ...this.asset,
          ...tempAssets.docs.map(item => item.data())[0],
        }
        this.asset = a;
        let donation = await fb.db.collection("donations").doc(this.asset.donations).get();
        this.paymentID = donation.data().paymentID;
        }
    },
    mounted() {
       if(this.globalRefurbisher){
           this.getAssetsDetail()
       }
           this.$loadScript("https://storage.googleapis.com/tech-reuse.appspot.com/DYMO.Label.Framework.3.0.js")
      .then(() => {
        // eslint-disable-next-line
        this.dymo = dymo.label.framework
        var printers = this.dymo.getPrinters();
        for (var i = 0; i < printers.length; i++) {
          var printer = printers[i];
          if (printer.printerType == "LabelWriterPrinter") {
            this.printerList.push(printer.name)
          }
        }

      })
    },
        computed: {
    ...mapFields(["globalRefurbisher"]
    )
  }
}
</script>