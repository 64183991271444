<template>
  <div class="home">
    <div
      class='ml-5'
      v-for='(item,index) in dahsboardValue'
      :key='index'
    >
      <h3>Non-profit : {{getNonprofitName(item.id)}}</h3>
      <v-row>
        <v-col cols="3">
          <v-card
            title='Unreceived donation'
            @click='goTo(item,"new")'
            elevation="5"
            color="green"
            max-width="400"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-truck-delivery</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Unreceived donation</v-list-item-title>
                  <v-list-item-subtitle class="display-2 font-weight-black">{{item.newDonation}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            title='Unprocessed'
            @click='goTo(item,"received")'
            elevation="5"
            color="green"
            max-width="400"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-text-box-search-outline</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Unprocessed</v-list-item-title>
                  <v-list-item-subtitle class="display-2 font-weight-black">{{item.pendingReviewDonations}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            title='Completed Donations'
            @click='goTo(item,"completed")'
            elevation="5"
            color="green"
            max-width="400"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-check-bold</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Completed Donations</v-list-item-title>
                  <v-list-item-subtitle class="display-2 font-weight-black">{{item.completedReviewDonations}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            title='Total Donations'
            @click='goTo(item,"all")'
            elevation="5"
            color="green"
            max-width="400"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-package-variant</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Total Donations</v-list-item-title>
                  <v-list-item-subtitle class="display-2 font-weight-black">{{item.totalDonation}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card
            title='Confirmed Balance'
            @click='goToURL("payment")'
            elevation="5"
            color="green"
            max-width="400"
          >
            <v-list three-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-cash-usd</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Confirmed Balance</v-list-item-title>
                  <v-list-item-subtitle class="display-2 font-weight-black">${{item.confirmedBalance}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>

          </v-card>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
const fb = require("../../firebaseconfig");
import { mapFields } from "vuex-map-fields";
export default {
  name: "Home",
  mounted() {
    this.updateDashboard();
  },
  methods: {
    goToURL(urlName) {
      this.$router.push(urlName);
    },
    goTo(item, status) {
      this.$router.push(
        "/npoDonations/" +
          item.id +
          "/" +
          this.getNonprofitName(item.id) +
          "?status=" +
          status
      );
    },
    getNonprofitName(name) {
      return this.ngoData.find((item) => item.id === name).name;
    },
    updateDashboard() {
      this.dahsboardValue = [];
      this.ngoData.forEach((n) => {
        fb.db
          .collection("counters")
          .doc("donationList")
          .collection("counters")
          .doc(n.id)
          .onSnapshot((data) => {
            let target = this.dahsboardValue.findIndex(
              (d) => d.id === data.data().id
            );
            if (target !== -1) {
              this.$set(this.dahsboardValue, target, data.data());
            } else {
              this.dahsboardValue.push(data.data());
            }
          });
      });
    },
  },
  data() {
    return {
      dahsboardValue: [],
    };
  },
  components: {},
  computed: {
    ...mapFields(["globalRefurbisher", "ngoData"]),
  },
  watch: {
    // whenever question changes, this function will run
    ngoData() {
      this.updateDashboard();
    },
  },
};
</script>
