import * as firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/functions"
import "firebase/storage"

// firebase init goes here
firebase.initializeApp(
{
        apiKey: "AIzaSyCYfS8RVAm-_iTAHQfrICyUnNj9kg0FSTk",
        authDomain: "donorconnection-df169.firebaseapp.com",
        databaseURL: "https://donorconnection-df169.firebaseio.com",
        projectId: "donorconnection-df169",
        storageBucket: "donorconnection-df169.appspot.com",
        messagingSenderId: "398092324898",
        appId: "1:398092324898:web:64d96cf1dd2f2888b97a7b",
        measurementId: "G-BS75R75MVE"
}
)

// firebase utils
const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const authh = firebase.auth
const currentUser = auth.currentUser
const functions = firebase.functions()
const storage = firebase.storage()
// date issue fix according to firebase
const settings = {
  //timestampsInSnapshots: true
}
db.settings(settings)


export {
  db,
  auth,
  authh,
  storage,
  firestore,
  functions,
  currentUser,
}
