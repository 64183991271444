<template>
    <v-container>
    
     <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Reset Password
        </v-card-title>

        <v-card-text>
           <v-text-field
           class='mt-5'
            v-model='resetEmail'
            label="enter your email here"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="sendResetPassword"
          >
            Send Reset Password Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
        <v-layout>
    
    
    
            <v-row>
    
    
    
                <v-col cols='6'>
    
    
    
                    <v-img src='https://storage.googleapis.com/donorconnection-df169.appspot.com/kingdom-fixing-web-page.png' />
    
    
    
                </v-col>
    
    
    
    
    
    
    
                <v-col cols='4'>
    
    
    
                    <v-form class='ml-5 center' ref="form" v-model="valid" lazy-validation>
    
    
    
                        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
    
    
    
    
    
    
    
                        <v-text-field type='password' v-model="password" :rules="passwordRules" label="Password" required></v-text-field>
    
    
    
    
    
                         <v-btn  @click="dialog = true"  color="gray"  class="mr-4">
                            Reset Password
                        </v-btn>

    
                        <v-btn :disabled="!valid" color="success" style='float: right;' class="mr-4" @click="validate">
    
    
    
                            Login
    
    
    
                        </v-btn>
    
    
    
                    </v-form>
    
    
    
                </v-col>
    
    
    
            </v-row>
    
    
    
        </v-layout>
    
    
    
    
    
    
    
    </v-container>
</template>

<script>
import axios from "axios"
import { mapFields } from 'vuex-map-fields';
const fb = require('../../firebaseconfig')

export default {
    mounted() {
        fb.auth.signOut().then(() => {
            this.user = null
            this.isAuthenticated = false
            // this.$router.push('/')

        });
        if (this.user !== null) {
            this.$router.push('/home');
        }
    },
    data() {
        return {
             resetEmail : null,
            dialog : false,
            valid: true,
            password: '',
            passwordRules: [
                v => !!v || 'password is required',
            ],
            email: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }
    },
    methods: {
        sendResetPassword(){
                        axios.post('https://us-central1-donorconnection-df169.cloudfunctions.net/partnerapi/resetpw', {
               email : this.resetEmail
            }, { headers: { "Content-Type": "application/json" } }).then(() => {
                this.resetEmail = null;
                this.dialog = false
            })
            
        },
        validate() {
            if (this.$refs.form.validate()) {
                fb.auth.signInWithEmailAndPassword(this.email, this.password)
                    .then((user) => {
                        fb.auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
                            if (idTokenResult.claims.role === "npo") {
                                alert("unauthorize access");
                                fb.auth.signOut().then(() => {
                                    this.user = null
                                    this.isAuthenticated = false
                                });
                            }
                        })
                                
                        this.user = user
                        this.isAuthenticated = true
                        this.$router.push('/home');
                        this.loginBtnDisable = false;

                    })
                    .catch(err => {
                        console.log(err)
                        this.user = null
                        this.isAuthenticated = false
                    })

            }
        },
    },
    computed: {
        ...mapFields([
            'isAuthenticated',
            'user',
        ])
    },

}
</script>

<style scoped>
.vertical-center {
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center {
    position: relative;
    top: 40%
}
</style>