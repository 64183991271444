import Vue from "vue"
import Vuex from "vuex"
import { getField, updateField } from "vuex-map-fields"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isAuthenticated: false,
    globalRefurbisher : null,
    ngoData : [],
  },
  mutations: {
    updateField
  },
  getters: {
    getField
  }
})
